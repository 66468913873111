import { useState } from 'react';
import { toast } from 'react-toastify';
import { triggerDownload } from 'http/file-storage';
import ColoredText from 'design/atoms/ColoredText';

const DocgenDownloadLink = (props) => {
    const [working, setWorking] = useState(false);

    const handleDownload = async () => {
        setWorking(true);

        try {
            await props.regenerateDocument();
            const pdfFileID = props?.value?.pdf;
            if (!pdfFileID) {
                throw new Error('PDF was not generated...');
            }
        
            await triggerDownload(pdfFileID, 'attachment');
        } catch (e) {
            toast.error('Kunne ikke downloade PDF...');
        } finally {
            setWorking(false);
        }
    };

    return (
        <ColoredText
            link
            loading={working}
            disabled={working}
            underlined={false}
            icon='download'
            iconPosition='left'
            content={props.label}
            onClick={handleDownload}
        />
    );
};

export default DocgenDownloadLink;