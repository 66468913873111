import React, { useEffect, useRef, useState } from 'react';
import { Button, Icon, Modal, Table } from 'semantic-ui-react';
import ColoredText from 'design/atoms/ColoredText';
import { deleteFile, getFileMetadata, triggerDownload, uploadFile } from 'http/file-storage';
import { formatDateTime } from 'util/format/DateTime';
import DeleteIcon from './DeleteIcon';

const AttachmentsViewer = ({ accountInfo, updateAccountInfo, accountNumber }) => {
    const hiddenFileuploadField = useRef();
    const dropZoneRef = useRef();
    const [working, setWorking] = useState(false);
    const [fullFiles, setFullFiles] = useState([]);

    const attachedFiles = accountInfo?.attachedFiles || [];

    useEffect(() => {
        if (attachedFiles.length === 0) {
            return;
        }

        const fetchFullFiles = async () => {
            setWorking(true);

            const fullFiles = await Promise.all(attachedFiles.map(fileID => {
                return getFileMetadata(fileID);
            }));

            setFullFiles(fullFiles.reverse());
            setWorking(false);
        };

        fetchFullFiles();
    }, [attachedFiles]);

    const onSelectFile = async e => {
        const files = e.target.files || e.dataTransfer.files;
        if (files.length !== 1) {
            return;
        }

        const [fileToUpload] = files;

        setWorking(true);

        const fileID = await uploadFile(fileToUpload);

        await updateAccountInfo(accountNumber, {
            ...accountInfo,
            attachedFiles: [...attachedFiles, fileID],
        });

        setWorking(false);
        
        if (e.target.value) {
            e.target.value = '';
        }
    };
    const handleDragOver = e => {
        e.preventDefault();
        e.stopPropagation();
        dropZoneRef.current.style.border = '2px solid #ccc';
    };

    const handleDragLeave = e => {
        e.preventDefault();
        e.stopPropagation();
        dropZoneRef.current.style.border = '2px dashed #ccc';
    };

    const handleDrop = e => {
        e.preventDefault();
        e.stopPropagation();
        dropZoneRef.current.style.border = '2px dashed #ccc';
        onSelectFile(e);
    };

    const renderFileList = () => {
        if (fullFiles.length === 0) {
            return (
                <strong>
                    <Icon name='spinner' loading />
                    Indlæser filer...
                </strong>
            );
        }

        return (
            <Table compact='very' basic='very'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Filnavn</Table.HeaderCell>
                        <Table.HeaderCell>Dato for upload</Table.HeaderCell>
                        <Table.HeaderCell />
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {fullFiles.map(({ id, name, updatedAt }) => {
                        const handleDelete = async () => {
                            setWorking(true);
                            await deleteFile(id);
                            await updateAccountInfo(accountNumber, {
                                ...accountInfo,
                                attachedFiles: attachedFiles.filter(file => file !== id),
                            });
                            setWorking(false);
                        };

                        return (
                            <Table.Row key={id}>
                                <Table.Cell>{name}</Table.Cell>
                                <Table.Cell>{formatDateTime(updatedAt)}</Table.Cell>
                                <Table.Cell textAlign='right'>
                                    <Icon name='eye' link onClick={() => triggerDownload(id, 'inline')} />
                                    <Icon name='download' link onClick={() => triggerDownload(id, 'attachment')} />
                                    <DeleteIcon text='Slet fil' onDelete={handleDelete} />
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        );
    };

    return (
        <>
            <Modal.Content scrolling>
                <div
                    ref={dropZoneRef}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    style={{ border: '2px dashed #ccc', padding: '20px', textAlign: 'center' }}
                >
                    {
                        attachedFiles.length === 0
                            ? <ColoredText color='grey' italic>Ingen bilag vedhæftet...</ColoredText>
                            : renderFileList()
                    }
                    <p>Træk og slip filer her eller klik på knappen nedenfor for at uploade.</p>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <input
                    type='file'
                    ref={hiddenFileuploadField}
                    style={{ display: 'none' }}
                    onChange={onSelectFile}
                />
                <Button
                    content='Nyt bilag'
                    icon='plus'
                    disabled={working}
                    onClick={() => hiddenFileuploadField.current.click()}
                />
            </Modal.Actions>
        </>
    );
};

export default AttachmentsViewer;