import { OVERSKRIFT, SUM } from './accountTypes';
import { accountantCodeDescriptions, xbrlTagDescriptions } from './tagDescriptions';

const WARNING_THRESHOLD = 0.9;

const postProcessAccount = (
    account,
    modelXbrlTags,
    modelAccountantCodes,
    accountToResourceTable,
    resourceMappableXbrlTags,

    auditorsDocumentationIsActive,
    materialityLevel,
    questionGroups,
    questionAnswers,
    customGroupQuestions,
) => {
    const mappingGroups = [
        {
            mapping: account.taxonomy,
            allowedTags: modelXbrlTags,
            tagDescriptions: xbrlTagDescriptions,
        },
        {
            mapping: account.accountantCode,
            allowedTags: modelAccountantCodes,
            tagDescriptions: accountantCodeDescriptions,
        },
    ];

    const amountZeroBothYears = (
        !Number(account.currentYearAmount) &&
        !Number(account.lastYearAmount)
    );

    const [xbrlMapping, accountantCodeMapping] = mappingGroups.map(({ mapping, allowedTags, tagDescriptions }) => {
        const { value, overrideValue, confidence } = mapping;
        const chosenTag = overrideValue || value;

        const checkForCriticalError = () => {
            if (account.masterAccountNumber !== undefined) {
                return false;
            }

            if (amountZeroBothYears) {
                return false;
            }

            if (chosenTag.toLowerCase() === 'none') {
                return false;
            }
    
            if (!allowedTags.has(chosenTag)) {
                return true;
            }
    
            const tagDescription = tagDescriptions[chosenTag];
            if (!tagDescription) {
                return true;
            }
    
            if (tagDescription.kind !== account.accountType) {
                return true;
            }
    
            return false;
        };

        const isOverridden = !!overrideValue;

        const hasCriticalError = checkForCriticalError();
        const hasWarning = (
            account.masterAccountNumber === undefined &&
            !hasCriticalError &&
            !amountZeroBothYears &&
            !isOverridden &&
            confidence < WARNING_THRESHOLD
        );

        return {
            confidencePercentage: Math.round(confidence * 100),
            label: tagDescriptions[chosenTag]?.description || chosenTag,
            chosenTag,
            hasCriticalError,
            hasWarning,
            isOverridden,
        };
    });

    const resourceMappingMissing = (
        resourceMappableXbrlTags.has(xbrlMapping.chosenTag) &&
        !amountZeroBothYears &&
        !(account.number in accountToResourceTable)
    );

    // TODO: should also check last year amount?
    const amountIsAboveMaterialityLevel = Math.abs(Number(account.currentYearAmount)) >= materialityLevel;
    const shouldAssociateAccountWithQuestionGroup = auditorsDocumentationIsActive && amountIsAboveMaterialityLevel;
    const matchingQuestionGroup = shouldAssociateAccountWithQuestionGroup && questionGroups.find(questionGroup => {
        return questionGroup.triggeringXbrlTags.includes(xbrlMapping.chosenTag);
    });

    let associatedQuestionsGroup;
    let amountOfUnansweredQuestions = 0;
    if (matchingQuestionGroup) {
        const usersCustomQuestions = customGroupQuestions[matchingQuestionGroup.id] || [];
        associatedQuestionsGroup = {
            groupID: matchingQuestionGroup.id,
            questionsAndAnswers: matchingQuestionGroup.questions.map(question => {
                const answerValue = questionAnswers[question.id] || '';

                if (!answerValue) amountOfUnansweredQuestions++;

                return {
                    ...question,
                    answerValue,
                };
            }),
            customQuestionsAndAnswers: usersCustomQuestions,
        };
    }

    return {
        ...account,
        xbrlMapping,
        accountantCodeMapping,
        resourceMappingMissing,
        associatedQuestionsGroup,
        amountOfUnansweredQuestions,
    };
};

const orderPostEntriesByAccountNumber = draftPostEntries => {
    const postEntriesByAccountNumber = {};
    
    for (const { date, text, entryNumber, affectedAccounts } of draftPostEntries) {
        for (const { accountNumber, amount } of affectedAccounts) {
            postEntriesByAccountNumber[accountNumber] = postEntriesByAccountNumber[accountNumber] || []
            postEntriesByAccountNumber[accountNumber].push({
                date,
                text,
                entryNumber,
                accountNumber,
                amount,
            });
        }
    }

    return postEntriesByAccountNumber;
};

const collectAccountsByAccountNumber = ({ currentYear, lastYear, checkedAccounts }) => {
    const accountsByNumber = {};
    const headersByNumber = {};

    const initAccount = ({ number, unroundedAmount, ...account }) => {
        const newAccount = {
            ...account,
            number,
            isChecked: checkedAccounts[number] || false,
            currentYearAmount: 0,
            lastYearAmount: 0,
            unroundedCurrentYearAmount: 0,
            unroundedLastYearAmount: 0,
            postEntries: [],
        };

        return newAccount;
    };

    for (const { amount, unroundedAmount, ...account } of currentYear) {
        if (account.accountType === OVERSKRIFT) {
            headersByNumber[account.number] = initAccount(account);
            continue;
        }
        accountsByNumber[account.number] = initAccount(account);
        accountsByNumber[account.number].currentYearAmount = Number(amount);
        if (unroundedAmount) {
            accountsByNumber[account.number].unroundedCurrentYearAmount = Number(unroundedAmount);

        }
    }
    
    for (const { amount, unroundedAmount, ...account } of lastYear) {
        if (account.accountType === OVERSKRIFT) {
            headersByNumber[account.number] = initAccount(account);
            continue;
        }

        if (!accountsByNumber[account.number]) {
            accountsByNumber[account.number] = initAccount(account);
        }

        accountsByNumber[account.number].lastYearAmount = Number(amount);
        if (unroundedAmount) {
            accountsByNumber[account.number].unroundedLastYearAmount = Number(unroundedAmount);

        }
    }

    return {
        accountsByNumber,
        headersByNumber,
    };
};

export const formatAccounts = ({
    currentYear,
    lastYear,
    modelXbrlTags,
    modelAccountantCodes,
    checkedAccounts = {},
    draftPostEntries = [],
    archivedPostEntries = [],
    childToMasterAccountTable = {},
    accountToResourceTable = {},
    resourceMappableXbrlTags = new Set(),

    // fields related to auditors documentation
    auditorsDocumentationIsActive = false,
    materialityLevel = 0,
    questionGroups = [],
    questionAnswers = {},
    customGroupQuestions = {},
}) => {
    const { accountsByNumber, headersByNumber } = collectAccountsByAccountNumber({
        currentYear,
        lastYear,
        checkedAccounts,
    });

    const draftPostEntriesByAccountNumber = orderPostEntriesByAccountNumber(draftPostEntries);

    // apply post entry amounts to the account plan + save the post entries on the given accounts
    for (const [accountNumber, postEntries] of Object.entries(draftPostEntriesByAccountNumber)) {
        accountsByNumber[accountNumber].currentYearAmount += postEntries.reduce((acc, cur) => acc += Number(cur.amount), 0);
        accountsByNumber[accountNumber].unroundedCurrentYearAmount += postEntries.reduce((acc, cur) => acc += Number(cur.amount), 0);   
        accountsByNumber[accountNumber].postEntries.push(...postEntries);
    }

    const archivedPostEntriesByAccountNumber = orderPostEntriesByAccountNumber(archivedPostEntries);

    // only apply post entry amounts to the account plan for archived post entries
    for (const [accountNumber, postEntries] of Object.entries(archivedPostEntriesByAccountNumber)) {
        accountsByNumber[accountNumber].currentYearAmount += postEntries.reduce((acc, cur) => acc += Number(cur.amount), 0);
        accountsByNumber[accountNumber].unroundedCurrentYearAmount += postEntries.reduce((acc, cur) => acc += Number(cur.amount), 0);   
    }

    // connect child accounts to master accounts (and vice versa)
    for (const account of Object.values(accountsByNumber)) {
        const masterAccountNumber = childToMasterAccountTable[account.number];
        if (masterAccountNumber === undefined) {
            continue;
        }

        account.masterAccountNumber = masterAccountNumber;

        const masterAccount = accountsByNumber[masterAccountNumber];
       
        masterAccount.childAccounts = masterAccount.childAccounts || [];
        masterAccount.childAccounts.push(account);

        if (!masterAccount.groupTotal) {
            masterAccount.groupTotal = {
                currentYear: masterAccount.currentYearAmount,
                lastYear: masterAccount.lastYearAmount,
                unroundedCurrentYear: masterAccount.unroundedCurrentYearAmount || masterAccount.currentYearAmount,
                unroundedLastYear: masterAccount.unroundedLastYearAmount || masterAccount.lastYearAmount,
            };
        }

        masterAccount.groupTotal.currentYear += account.currentYearAmount;
        masterAccount.groupTotal.lastYear += account.lastYearAmount;
        masterAccount.groupTotal.unroundedCurrentYear += account.unroundedCurrentYearAmount || account.currentYearAmount;
        masterAccount.groupTotal.unroundedLastYear += account.unroundedLastYearAmount || account.lastYearAmount;
    }

    const unsortedAccountPlan = [...Object.values(accountsByNumber), ...Object.values(headersByNumber)];
    const accountplan = unsortedAccountPlan.sort((a, b) => {
        if (a.masterAccountNumber === b.masterAccountNumber) {
            const [aVal, bVal] = [a, b].map(account => {
                // ensure headers will come first if two accounts have the same number
                const isOverskriftModifier = Number(account.accountType === OVERSKRIFT);
                return account.number - isOverskriftModifier;
            });

            return aVal - bVal;
        }

        // an account grouped under a master account should always appear below the master account
        if (a.number === b.masterAccountNumber) {
            return -1;
        }

        const anum = a.masterAccountNumber !== undefined ? a.masterAccountNumber : a.number;
        const bnum = b.masterAccountNumber !== undefined ? b.masterAccountNumber : b.number;

        return anum - bnum;
    });

    return accountplan
        .filter(account => account.accountType !== SUM)
        .map(account => {
            return postProcessAccount(
                account,
                modelXbrlTags,
                modelAccountantCodes,
                accountToResourceTable,
                resourceMappableXbrlTags,
                auditorsDocumentationIsActive,
                materialityLevel,
                questionGroups,
                questionAnswers,
                customGroupQuestions,
            );
        });
};