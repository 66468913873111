import React from 'react';
import { Icon, Menu, Modal } from 'semantic-ui-react';
import { CLOSING_SHEET_QUESTIONS } from 'flags';
import EntriesViewer from './EntriesViewer';
import AttachmentsViewer from './AttachmentsViewer';
import CommentsViewer from './CommentsViewer';
import QuestionsViewer from './QuestionsViewer';

export const accountOptionsCategories = {
    postings: {
        title: 'Posteringer',
        indicatorTitle: {
            singular: 'efterpostering',
            plural: 'efterposteringer',
        },
        icon: 'list',
        Component: EntriesViewer,
        isVisible: () => true,
    },
    attachments: {
        title: 'Bilag',
        indicatorTitle: {
            singular: 'bilag',
            plural: 'bilag',
        },
        icon: 'attach',
        Component: AttachmentsViewer,
        isVisible: () => true,
    },
    notes: {
        title: 'Noter',
        indicatorTitle: {
            singular: 'note',
            plural: 'noter',
        },
        icon: 'sticky note',
        Component: CommentsViewer,
        isVisible: () => true,
    },
    questions: {
        title: 'Spørgsmål',
        indicatorTitle: {
            singular: 'Spørgsmål',
            plural: 'Spørgsmål',
        },
        icon: 'question circle',
        Component: QuestionsViewer,
        isVisible: account => CLOSING_SHEET_QUESTIONS() && account.associatedQuestionsGroup?.questionsAndAnswers?.length > 0,
    },
};

export const getAccountOptionsMenuItems = (account) => {
    return Object.values(accountOptionsCategories).filter(category => {
        return category.isVisible(account);
    });
};

const AccountOptions = ({ header, onClose, activeMenuItemIndex, setActiveMenuItemIndex, showDecimalAccounts, menuItemsWithActivity, currentYearAmount,unroundedCurrentYearAmount, ...props }) => {
    const accountOptionMenuItems = getAccountOptionsMenuItems(props.account);

    const renderMenu = () => (
        <Menu
            tabular
            attached='top'
            style={{
                marginBottom: '-1px',
                marginTop: '1em',
            }}
        >
            {accountOptionMenuItems.map(({ title, icon, Component }, i) => {
                return (
                    <Menu.Item
                        content={title}
                        active={i === activeMenuItemIndex}
                        onClick={() => setActiveMenuItemIndex(i)}
                        link={i !== activeMenuItemIndex}
                        icon={
                            <Icon
                                name={icon}
                                color={menuItemsWithActivity.has(Component) ? 'green' : 'black'}
                            />
                        }
                    />
                );
            })}
        </Menu>
    );

    const activeItem = accountOptionMenuItems[activeMenuItemIndex];
    const Component = activeItem?.Component;

    return (
        <Modal open onClose={onClose} closeIcon centered={false} closeOnDimmerClick={false}>
            <Modal.Header style={{ paddingBottom: 0 }}>
                {header}
                {renderMenu()}
            </Modal.Header>
            {Component && <Component {...props} showDecimalAccounts={showDecimalAccounts} currentYearAmount={props.account.currentYearAmount} unroundedCurrentYearAmount={props.account.unroundedCurrentYearAmount}/>}
        </Modal>
    );
};

export default AccountOptions;