import React, { useState } from 'react';
import { Button, Form, Icon, Input, Modal, Popup } from 'semantic-ui-react';
import debounce from 'lodash.debounce';

const Question = ({ questionText, answer, onAnswer, onDelete }) => {
    const deleteIcon = onDelete && (
        <Icon
            onClick={onDelete}
            name='trash'
            color='red'
            link
        />
    );

    return (
        <Form.Field>
            <label>{questionText} {deleteIcon}</label>
            <textarea
                onChange={e => onAnswer(e.target.value)}
                defaultValue={answer}
            />
        </Form.Field>
    );
};

const QuestionsViewer = ({ account, provideQuestionAnswer, addCustomQuestionToGroup, deleteCustomQuestionFromGroup }) => {
    const [newQuestionText, setNewQuestionText] = useState('');
    const [newQuestionFormOpen, setNewQuestionFormOpen] = useState(false);

    const { associatedQuestionsGroup } = account;

    const handleQuestionAnswerChange = debounce((questionID, questionAnswerValue, isCustomQuestion) => {
        provideQuestionAnswer(associatedQuestionsGroup.groupID, questionID, questionAnswerValue, isCustomQuestion);
    }, 600);

    const saveNewQuestionGroup = async () => {
        if (!newQuestionText) return;

        await addCustomQuestionToGroup({
            groupID: associatedQuestionsGroup.groupID,
            questionText: newQuestionText,
        });

        setNewQuestionText('');
        newQuestionFormOpen(false);
    };

    const systemDefinedQuestionsAndAnswers = associatedQuestionsGroup?.questionsAndAnswers || [];
    const usersCustomQuestionsAndAnswers = associatedQuestionsGroup?.customQuestionsAndAnswers || [];

    const questionForm = systemDefinedQuestionsAndAnswers.length > 0 && (
        <Form>
            {systemDefinedQuestionsAndAnswers.map(({ id, questionText, answerValue }) => {
                return (
                    <Question
                        key={id}
                        questionText={questionText}
                        answer={answerValue}
                        onAnswer={answer => handleQuestionAnswerChange(id, answer, false)}
                    />
                );
            })}

            {usersCustomQuestionsAndAnswers.map(({ questionID, questionText, answerValue }) => {
                return (
                    <Question
                        key={questionID}
                        questionText={questionText}
                        onDelete={() => deleteCustomQuestionFromGroup(associatedQuestionsGroup.groupID, questionID)}
                        answer={answerValue}
                        onAnswer={answer => handleQuestionAnswerChange(questionID, answer, true)}
                    />
                );
            })}

            <Form.Field>
                <Popup
                    flowing
                    basic
                    position='top center'
                    on='click'
                    open={newQuestionFormOpen}
                    onClose={() => setNewQuestionFormOpen(false)}
                    content={
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5em' }}>
                            <strong>Indtast spørgsmål:</strong>
                            <Input
                                placeholder='Indtast spørgsmål'
                                autoFocus
                                onChange={(_, { value }) => setNewQuestionText(value)}
                                onKeyDown={e => e.key === 'Enter' && saveNewQuestionGroup()}
                            />
                            <Button
                                fluid
                                primary
                                content='Gem'
                                disabled={!newQuestionText}
                                onClick={saveNewQuestionGroup}
                            />
                        </div>
                    }
                    trigger={
                        <Button
                            fluid
                            basic
                            color={newQuestionFormOpen ? 'grey' : 'black'}
                            content='Tilføj nyt spørgsmål'
                            icon='plus'
                            onClick={() => setNewQuestionFormOpen(true)}
                        />
                    }
                />
            </Form.Field>
        </Form>
    );
    
    return (
        <Modal.Content>
            {questionForm || <i>Ingen spørgsmål at vise...</i>}
        </Modal.Content>
    );
};

export default QuestionsViewer;